/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/**
 * Makes a request using the TomTom
 * [Search API - Structured Geocode](STRUCTURED_GEOCODE_URL).
 *
 * Parameters can be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Additionally, the response extends API response by providing `toGeoJson()` method, which converts structured geocode data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class structuredGeocode
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses LimitMixin
 * @uses OffsetMixin
 * @uses LanguageMixin
 * @uses ProtocolMixin
 * @uses BestResultMixin
 * @uses BatchMixin
 * @uses ViewMixin
 * @uses TimeZoneMixin
 * @uses MapcodesMixin
 * @uses TrackingIdMixin
 * @uses ExtendedPostalCodesForMixin
 * @uses EntityTypeSetMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the structured geocoding call,
 *     or an array of such options objects to perform batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 * @param {String} options.countryCode The two-letter code of the country being targeted.
 *
 * @example
 * ```javascript
 * function callbackFn(result) {
 *   console.log(result);
 * };
 * tt.services.structuredGeocode({
 *   key: <Your API key>,
 *   countryCode: 'GB'
 * });
 * ```
 */
/* eslint-enable max-len */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import {geocodeModel} from '../../model/geocode/geocode';
import {batchModel} from '../../model/geocode/batchGeocode';
import structureGeocodeParameters from './structureGeocodeParameters';
import {modelResponse} from '../../model/modelResponse';
import { Endpoints } from '../../endpoints/endpointsManager';
import {
    singleRequestServiceFactory, batchRequestServiceFactory, serviceFactory
} from '../../core';

const fields = structureGeocodeParameters();

function checkBestResult(data) {
    const result = Boolean(data.bestResult);
    delete data.bestResult;
    return result;
}

function modifyRequestParams(data) {
    const bestResult = checkBestResult(data);
    if (bestResult) {
        data.limit = 1;
        data.offset = 0;
    }
}

export function structuredGeocode(options, additionalOptions) {
    const endpoints = new Endpoints(additionalOptions);
    const batchRequest = batchRequestServiceFactory(100, {
        single: endpoints.resolve('batchStructuredGeocodeQueryEndpoint'),
        batch: endpoints.resolve('batchSearchEndpoint'),
        batchSync: endpoints.resolve('batchSyncSearchEndpoint')
    });
    const singleRequest = singleRequestServiceFactory(endpoints.resolve('structuredGeocodeEndpoint'));

    function handleBatchServiceCall(data, abortSignal) {
        data.batchItems.forEach(modifyRequestParams);
        return batchRequest(fields, data, abortSignal).then(response => modelResponse(response, batchModel));
    }

    function handleServiceCall(data, abortSignal) {
        const bestResult = Boolean(data.bestResult);
        modifyRequestParams(data);
        return singleRequest(fields, data, abortSignal)
            .then(({ data, trackingId }) => {
                return modelResponse({
                    data: bestResult ? data.results[0] : data,
                    trackingId
                }, geocodeModel);
            });
    }

    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'structuredGeocode',
        handleServiceCall,
        handleBatchServiceCall
    )(options, additionalOptions);
}
